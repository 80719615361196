<template>
  <div v-if="platformPermissionsLoaded && checkPermission('learn.insights.onboarding')">
    <CRow>
      <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
        <div class="d-flex">
          <h1 class="dashboard_page_title flex-grow-1">{{$t('insights.Onboarding_insights')}}</h1>
          <div v-if="showDownloadButtons" class="insights_download_buttons text-right">
            <div class="mt-1 mt-xl-0 d-inline-block align-top">
              <CButton class="d-inline-block" color="primary" @click="makeScreenshot()">
                <i class="fas fa-image mr-1"/><span>{{ $t('insights.Save_as_image') }}</span>
              </CButton>
              <CButton class="d-inline-block m-0" color="primary" @click="exportData('json');">
                <i class="fas fa-file-arrow-down mr-1"/><span>{{ $t('common.Export_as_json') }}</span>              
              </CButton>                             
            </div>
          </div>
        </div>
      </CCol>
    </CRow>

    <CRow class="insights_filters">
      <CCol cols="12" xl="12" lg="12" md="12" sm="12">
        <multiselect
          class="data_table open_absolute multiple"
          v-model="selectedTeams"
          :options="departments" 
          :multiple="true"
          :hide-selected="true"
          :group-select="true"
          group-values="teams" 
          group-label="department_name"
          :placeholder="$t('common.Select_teams')" 
          :selectLabel="$t('common.Add_team')"
          :deselectLabel="$t('common.Remove_team')"
          track-by="team_id" 
          label="team_name"
          @input="getOnboardingInsightsData();">
          <span slot="noResult">{{ $t('common.no_teams_found') }}</span>
        </multiselect>        
      </CCol>
    </CRow>

    <CRow ref="screenshotContent" class="insights" v-bind:class="{'printing' : printingInsights}">                    
      <CCol cols="4" xl="4" lg="4" md="4" sm="4">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Employees_part_of_onboarding')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Count v-else mode="single_number" :count="onboardingInsights.employees_working_on_onboarding" :countTitle="null" :countIcon="null"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="4" xl="4" lg="4" md="4" sm="4">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Employees_finished_onboarding')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Count v-else mode="single_number" :count="onboardingInsights.employees_finished_onboarding" :countTitle="null" :countIcon="null"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="4" xl="4" lg="4" md="4" sm="4">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Percentage_finished_onboarding')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Gauge v-else :gaugeChartSeries="gaugeChartSeries" :companyPrimaryColor="companyPrimaryColor"/>
          </CCardBody>
        </CCard>
      </CCol>            
      
      <CCol col="12" xl="12">
        <CCard class="mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">              
                {{ $t('insights.Overview_onboarding') }}
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class="pt-0" v-bind:class="{'p-0' : !loadingInsightsData}">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <OnboardingTable v-else :employees="onboardingInsights.onboarding_employees"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol col="12" xl="12">
        <CCard class="mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">              
                {{ $t('insights.Unfinished_onboarding') }}
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class="p-0">
            <OnboardingUnfinishedTable :teams="selectedTeams"/>
          </CCardBody>
        </CCard>
      </CCol>      
    </CRow>
  </div>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';

import Count from '@/components/insights/common/Count.vue';
import Gauge from '@/components/insights/common/Gauge.vue';

import OnboardingTable from '@/components/insights/learn/OnboardingTable.vue';
import OnboardingUnfinishedTable from '@/components/insights/learn/OnboardingUnfinishedTable.vue';

export default {
  name: 'Onboarding',
  components: {
    Multiselect,
    loadingSpinner,
    noPermission,
    Count,
    Gauge,
    OnboardingTable,
    OnboardingUnfinishedTable
  }, 
  data: () => {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      companyPrimaryColor: null,
      onboardingInsights: {
        onboarding_employees: [],        
      },
      loadingInsightsData: false,
      showDownloadButtons: false,
      printingInsights: false,
      dateFrom: new Date(),
      selectedTeams: [],
      departments: [],
      gaugeChartSeries: [],
    }
  },
  methods: {
    getOnboardingInsightsData() {
      let params = {};
      params.teams = [];
            
      for(let t = 0; t < this.selectedTeams.length; t++) {
        params.teams.push(this.selectedTeams[t].team_id);
      }
      
      // Start the loader
      this.loadingInsightsData = true;
      // Clear the gaugeChartSeries array
      this.gaugeChartSeries = [];      
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");

      axios.post(process.env.VUE_APP_API_URL + '/v1/learn/insights/onboarding/' + dateFromAPI, params)
      .then(res => {  
        this.onboardingInsights = res.data.data;
        // Add the reach percentage to the gaugeChartSeries array
        if(this.onboardingInsights.employees_working_on_onboarding === 0) {
          this.gaugeChartSeries.push(0);
        } else {
          this.gaugeChartSeries.push(((this.onboardingInsights.employees_finished_onboarding / this.onboardingInsights.employees_working_on_onboarding)*100).toFixed(0));
        }
        // Set the companyPrimaryColor value
        this.companyPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary');        
        
        // Set timeout for 2 seconds
        setTimeout(function(){
          // Close the loader
          this.loadingInsightsData = false;
          // Update the showDownloadButtons value
          this.showDownloadButtons = true;
        }.bind(this), 2000);     
      })
      .catch(err => {
        console.error(err); 
      });
    }, 
    getDepartments() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/departmentTeams')
      .then(res => {
        // Get departments and teams from API response        
        var departments = res.data.data.departments;
        var teams = res.data.data.teams;
        // Empty departments array
        this.departments = [];        
        // Loop through departments
        for(var d = 0; d < departments.length; d++) {
          // Push new department into departments array
          this.departments.push({
            department_id: departments[d].department_id,
            department_name: departments[d].department_name,
            teams: []
          });

          // Loop through teams
          for(var t = 0; t < teams.length; t++) {
            // If a team is part of a department, push the team into the teams array of this department
            if(departments[d].department_id === teams[t].department_id) {
              var index = this.departments.findIndex(i => i.department_id == departments[d].department_id);
              this.departments[index].teams.push({
                team_id: teams[t].team_id,
                team_name: teams[t].team_name  + ' (' + departments[d].department_name + ')'
              });
            }
          }
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    makeScreenshot() {
      window.scroll(0,0);
      // Update printingInsights value
      this.printingInsights = true;
      // Get the HTML to print
      const content = this.$refs.screenshotContent;
      // Set the html2canvas options
      const options = { type: "dataURL", useCORS: true };

      setTimeout(function() {
        // Print the screenshot
        this.printScreenshot(content, options);
        // Reset the printingInsights value
        this.printingInsights = false;
      }.bind(this), 500);
    },
    async printScreenshot(content, options) {
      // Create canvas for printing
      const printCanvas = await html2canvas(content, options);
      // Creata a link element to download the generated canvas
      const link = document.createElement("a");
    
      link.setAttribute("download", "learn_onboarding_insights_" + new Date().toLocaleString() + ".png");
      link.setAttribute("href", printCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
      link.click();
    },
    exportData(outputData) {      
      this.$buefy.toast.open({ message: this.$t('common.Export_successful'), type: 'is-success', duration: 2000 });
               
      let exportFileName = 'learn-onboarding-insights';

      if(!window.navigator.msSaveOrOpenBlob){
        // Blob navigator
        let url = null;
        // Set the output data and URL
        let outputData = JSON.stringify(this.onboardingInsights);
        url = window.URL.createObjectURL(new Blob([outputData]));
        // Create a new 'a' element
        const link = document.createElement('a');
        // Set the generated URL as href of the new element
        link.href = url;
        // Set the download attribute
        link.setAttribute('download', exportFileName + '.json');
        // Append the link to the body
        document.body.appendChild(link);
        // Click the link
        link.click();
      } else {
        // Blob for Explorer 11
        let url = null;
        // Set the output data and URL        
        outpuData = JSON.stringify(this.onboardingInsights);
        url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]), exportFileName + '.json');
      }
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function(){
    // Set the default for dateFrom to today minus 90 days
    this.dateFrom.setDate(this.dateFrom.getDate() -90);
    
    this.getPlatformPermissions();

    // Get the insights data    
    this.getOnboardingInsightsData();
    this.getDepartments();
  }
}
</script>
