<template>
  <CRow>
    <CCol cols="12" lg="12" v-bind:class="{ 'pb-0 pt-0' : !loadingUnfinishedOnboardingData }">
      <loadingSpinner v-if="loadingUnfinishedOnboardingData" mode="auto" :content="null"/>
      <b-table v-else 
               class="data_table"
               :data="onboardingUnfinishedEmployees"
               :striped="true"
               :hoverable="true"
               :mobile-cards="true"
               :paginated="isPaginated"
               :per-page="perPage"
               :current-page.sync="currentPage"
               :pagination-simple="isPaginationSimple"
               :pagination-position="paginationPosition"
               detailed 
               detail-key="employee_user_id_external" 
               :show-detail-icon="true"
               :default-sort-direction="defaultSortDirection"
               :sort-icon="sortIcon"
               :sort-icon-size="sortIconSize"
               default-sort="average_percentage_completed"
               :total="totalItems"
               backend-pagination
               @page-change="onPageChange">
        
        <template slot-scope="props">
          <b-table-column field="image" width="2.5%">
            <userProfilePopover ref="userProfilePopover" mode="user" :userName="props.row.employee" :userProfileImage="props.row.employee_profile_image" :userIdExternal="props.row.employee_user_id_external" :senderIdExternal="null" popoverPlacement="right"/>          
          </b-table-column>
          <b-table-column field="employee" :label="$t('common.Employee')" :searchable="searchEnabled" :sortable="true" width="30%">
            <div class="d-flex flex-column">
              <span><b>{{props.row.employee}}</b></span>
              <div v-if="props.row.employee_date_started" class="mt-1 meta">
                <span>{{$t('common.Started_at_company')}}: {{props.row.employee_date_started}}</span>
                <span> | </span>
                <span v-if="props.row.employee_years_active > 0">{{props.row.employee_years_active}} {{$t('common.years_of_service') }}</span>
                <span v-else-if="props.row.employee_months_active > 0">{{props.row.employee_months_active}} {{$t('common.months_of_service') }}</span>    
                <span v-else>{{props.row.employee_days_active}} {{props.row.employee_days_active !== 1 ? $t('common.days_of_service') : $t('common.day_of_service') }}</span>            
              </div>
            </div>                      
          </b-table-column>
          <b-table-column field="department" :label="$t('common.Department')" :searchable="searchEnabled" :sortable="true" width="15%">
            <span>{{props.row.department}}</span>
          </b-table-column>
          <b-table-column field="team" :label="$t('common.Team')" :searchable="searchEnabled" :sortable="true" width="15%">
            <span>{{props.row.team !== null ? props.row.team : '-' }}</span>
          </b-table-column>
          <b-table-column field="user_managers" :label="$t('common.Executives')" :searchable="searchEnabled" :sortable="false" width="15%">
            <div v-if="props.row.user_managers.length > 0" class="user_managers d-flex align-items-center">
              <userProfilePopover ref="userProfilePopover"
                                  v-for="manager in props.row.user_managers"
                                  v-bind:key="manager.user_id_external"
                                  mode="user"
                                  :userName="manager.name"  
                                  :userProfileImage="manager.profile_image"
                                  :userIdExternal="manager.user_id_external"
                                  :senderIdExternal="null"
                                  popoverPlacement="right"/>
            </div>
            <span v-else>-</span>
          </b-table-column> 
          <b-table-column field="average_percentage_completed" sortable :label="$t('insights.Percentage_completed')" width="20%">
            <CProgress :max="100" show-value>
              <CProgressBar :value="props.row.average_percentage_completed"></CProgressBar>
              <span>{{parseFloat(props.row.average_percentage_completed).toFixed(0)}}%</span>
            </CProgress>
          </b-table-column>
        </template>
        <template slot="empty">
          <div class="p-2 text-center">
            <span>{{ $t('insights.No_onboarding_unfinished_employees') }}</span>
          </div>                
        </template>
        <template slot="detail" slot-scope="props">
          <b-table class="data_table" 
                    :data="Object.values(props.row.onboarding_todo_lists)" 
                    :striped="true" 
                    :hoverable="true" 
                    :mobile-cards="true" 
                    :paginated="false"
                    detailed 
                    detail-key="todo_list_id_external" 
                    :show-detail-icon="true"
                    default-sort-direction='desc'
                    :sort-icon="sortIcon"
                    :sort-icon-size="sortIconSize"
                    default-sort="percentage_completed">
            
            <template slot-scope="props">    
              <b-table-column field="name" sortable :label="$t('insights.Onboarding_todo_list')" :searchable="searchEnabled" width="30%">                  
                <div class="d-flex flex-column">
                  <span><b>{{props.row.name}}</b></span>
                </div>                            
              </b-table-column>                   
              <b-table-column field="total_items" sortable :label="$t('insights.Total_todo_list_items')" width="20%">                
                <span>{{props.row.total_items}}</span>
              </b-table-column>
              <b-table-column field="total_completed_items" sortable :label="$t('insights.Completed_todo_list_items')" width="20%">                
                <span>{{props.row.total_completed_items}}</span>
              </b-table-column>                      
              <b-table-column field="percentage_completed" sortable :label="$t('insights.Percentage_completed')" width="30%">
                <CProgress :max="100" show-value>
                  <CProgressBar :value="props.row.percentage_completed"></CProgressBar>
                  <span>{{parseFloat(props.row.percentage_completed).toFixed(0)}}%</span>
                </CProgress>
              </b-table-column>
            </template>
            <template slot="empty">
              <div class="p-2 text-center">                
                <span>{{$t('insights.No_onboarding_todo_lists')}}</span>
              </div>                
            </template>
            <template slot="detail" slot-scope="props">
              <b-table class="data_table" 
                        :data="Object.values(props.row.items)" 
                        :striped="true" 
                        :hoverable="true" 
                        :mobile-cards="true"
                        :paginated="false"                                                  
                        default-sort-direction='desc'
                        :sort-icon="sortIcon"
                        :sort-icon-size="sortIconSize"
                        default-sort="percentage_completed">
                
                <template slot-scope="props">            
                  <b-table-column field="title" sortable :label="$t('insights.Onboarding_todo_list')" :searchable="searchEnabled" width="40%">                    
                    <span><b>{{props.row.title}}</b></span>
                  </b-table-column>                   
                  <b-table-column field="onboarding_package_content_type" sortable :label="$t('learn.Onboarding_package_content_type')" width="30%">                    
                    <span>{{props.row.onboarding_package_content_type}}</span>
                  </b-table-column>
                  <b-table-column field="percentage_completed" sortable :label="$t('insights.Percentage_completed')" width="30%">
                    <div v-if="props.row.onboarding_package_content_type_tag === 'learn_course'">
                      <CProgress :max="100" show-value>
                        <CProgressBar :value="props.row.course.percentage_completed"></CProgressBar>
                        <span>{{parseFloat(props.row.course.percentage_completed).toFixed(0)}}%</span>
                      </CProgress>                      
                    </div>
                    <div v-if="props.row.onboarding_package_content_type_tag === 'knowledge_item'">
                      <CProgress :max="100" show-value>
                        <CProgressBar :value="props.row.item.percentage_completed"></CProgressBar>
                        <span>{{parseFloat(props.row.item.percentage_completed).toFixed(0)}}%</span>
                      </CProgress>
                    </div>                    
                  </b-table-column>
                </template>
                <template slot="empty">
                  <div class="p-2 text-center">
                    <span>{{$t('insights.No_onboarding_todo_list_items')}}</span>
                  </div>                
                </template>                
              </b-table>                      
            </template>                            
          </b-table>                      
        </template>
      </b-table>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios';
import userProfilePopover from '@/components/common/userProfilePopover.vue';
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'OnboardingUnfinishedTable',
  props: ['teams'],
  components: {
    userProfilePopover,
    loadingSpinner
  },
  watch: {
    $props: {
      handler() {
        // Reset the unfinishedOnboardingDataLoaded value so the loader will be shown
        this.unfinishedOnboardingDataLoaded = false;
        // Reset the currentPage value so the first paginatino page will be displayed
        this.currentPage = 1;
        // Reset the unfinishedOnboardingStartIndex value so the first batch of items will be loaded
        this.unfinishedOnboardingStartIndex = 0;
        // Set the default for dateFrom to today minus 90 days
        this.dateFrom.setDate(this.dateFrom.getDate() -90);        
        // Get the items for the table
        this.getUnfinishedOnboardingData();
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      onboardingUnfinishedEmployees: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      searchEnabled: false,
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      dateFrom: new Date(),
      loadingUnfinishedOnboardingData: false,
      unfinishedOnboardingDataLoaded: false,
      totalItems: 0,
      unfinishedOnboardingStartIndex: 0      
    }
  },
  methods: {
    onPageChange(page) {
      // Set the challengesStartIndex value
      (page > 1) ? this.unfinishedOnboardingStartIndex = (page - 1)*10 : this.unfinishedOnboardingStartIndex = 0;
      // Get the data
      this.getUnfinishedOnboardingData();
    },      
    getUnfinishedOnboardingData() {
      let params = {};
      // Set the params
      params.teams = [];                  
      for(let t = 0; t < this.teams.length; t++) {
        params.teams.push(this.teams[t].team_id);
      }
      // Show the loader
      if(!this.unfinishedOnboardingDataLoaded) this.loadingUnfinishedOnboardingData = true;      
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      // Get the employees with unfinished onboarding
      axios.post(process.env.VUE_APP_API_URL + '/v1/learn/insights/onboarding/unfinished/' + dateFromAPI + '/' + this.unfinishedOnboardingStartIndex, params)
      .then(res => {  
        // Clear the onboardingUnfinishedEmployees array
        this.onboardingUnfinishedEmployees = [];
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_onboarding_unfinished_employees;
        // Update the currentTotal value
        if(currentTotal / this.perPage > 1000) currentTotal = this.perPage * 1000;
        // Update the totalItems value
        this.totalItems = currentTotal;
        // Add the onboarding_unfinished_employees to the onboardingUnfinishedEmployees array
        this.onboardingUnfinishedEmployees = Object.values(res.data.data.onboarding_unfinished_employees);
        // Check if the pagination should be activated
        (this.totalItems > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        // Update the unfinishedOnboardingDataLoaded value
        this.unfinishedOnboardingDataLoaded = true;
        // Hide the loader
        this.loadingUnfinishedOnboardingData = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }
  },  	
  mounted:function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    this.$bus.$on('marked_user_onboarding_finished', (userIdExternal) => {
      this.getUnfinishedOnboardingData();
    })    
  },
  beforeDestroy() {
    this.$bus.$off('marked_user_onboarding_finished');
  }
}
</script>
